import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { DashboardDataResolver } from './modules/admin/dashboard/dashboard.resolvers';
import { ReportDataResolver } from './modules/admin/admin-report/report.resolvers';
import { AdminDataResolver } from './modules/admin/manage-admin/admin.resolvers';
import { UserDataResolver } from './modules/admin/manage-user/user.resolvers';
import { PromocodeDataResolver } from './modules/admin/manage-promocode/promocode.resolvers';
import { CategoryDataResolver } from './modules/admin/manage-category/category.resolvers';
import { SubcategoryDataResolver } from './modules/admin/manage-subcategory/subcategory.resolvers';
import { SizeDataResolver } from './modules/admin/manage-size/size.resolvers';
import { ColorDataResolver } from './modules/admin/manage-color/color.resolvers';
import { KeywordDataResolver } from './modules/admin/manage-keyword/keyword.resolvers';
import { FranchiseDataResolver } from './modules/admin/manage-franchise/franchise.resolvers';
import { FranchiseCategoryDataResolver } from './modules/admin/manage-franchise-category/franchise-category.resolvers';
import { OrderDataResolver } from './modules/admin/manage-orders/order.resolvers';
import { FeedbackDataResolver } from './modules/admin/manage-feedback/feedback.resolvers';
import { OrderDetailDataResolver } from './modules/admin/manage-order-details/order-detail.resolvers';
import { BannerDataResolver } from './modules/admin/manage-banner/banner.resolvers';
import { FaqCategoryDataResolver } from './modules/admin/manage-faq-category/faq-category.resolvers';
import { FaqDataResolver } from './modules/admin/manage-faq/faq.resolvers';
import { FaqVideoDataResolver } from './modules/admin/manage-faq-video/faq-video.resolvers';
import { ProductDataResolver } from './modules/admin/manage-product/product.resolvers';
import { ProductDetailDataResolver } from './modules/admin/manage-product/product-details/product-detail.resolvers';
import { ProductVersionDataResolver } from './modules/admin/manage-product/product-versions/product-version.resolvers';
import { PricingQuantityDataResolver } from './modules/admin/manage-pricing-quantity/pricing-quantity.resolvers';
import { FeatureDataResolver } from './modules/admin/manage-subscription/manage-feature/feature.resolvers';
import { SubscriptionCategoryDataResolver } from './modules/admin/manage-subscription/manage-category/subscription.category.resolvers';
import { ServicesDataResolver } from './modules/admin/manage-subscription/manage-service/service.resolvers';
import { InterestDataResolver } from './modules/admin/manage-subscription/manage-interest/interest.resolvers';
import { PlansDataResolver } from './modules/admin/manage-subscription/manage-plan/plan.resolvers';
import { DigitalSubscriptionResolver } from './modules/admin/manage-digital-subscriptions/digital-subscriptions.resolvers';
import { DigitalSubscriptionDetailDataResolver } from './modules/admin/manage-digital-subscription-details/digital-subscription-detail.resolvers';
import { TaxDataResolver } from './modules/admin/manage-tax/tax.resolvers';
import { EmailSignatureDataResolver } from './modules/admin/manage-email-signature/email-signature.resolvers';
import { DashboardComponent } from './modules/admin/dashboard/dashboard.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed in user to the '/dashboard'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') }
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes') },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            {
                path: 'dashboard',
                resolve: { admins: DashboardDataResolver },
                component: DashboardComponent
            },
            {
                path: 'reports',
                resolve: { reports: ReportDataResolver },
                loadChildren: () => import('app/modules/admin/admin-report/report.module').then(m => m.ReportModule)
            },
            {
                path: 'admin/manage', resolve: {
                    admins: AdminDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-admin/manage-admin.module').then(m => m.ManageAdminModule)
            },
            {
                path: 'user/manage', resolve: {
                    users: UserDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-user/manage-user.module').then(m => m.ManageUserModule)
            },
            {
                path: 'promocode/manage', resolve: {
                    promo_codes: PromocodeDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-promocode/manage-promocode.module').then(m => m.ManagePromocodeModule)
            },
            {
                path: 'profile', loadChildren: () => import('app/modules/admin/profile/profile.module').then(m => m.ProfileModule)
            },
            {
                path: 'category/manage', resolve: {
                    categories: CategoryDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-category/manage-category.module').then(m => m.ManageCategoryModule)
            },
            {
                path: 'category/manage/:id', resolve: {
                    subCategories: SubcategoryDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-subcategory/manage-subcategory.module').then(m => m.ManageSubcategoryModule)
            },
            {
                path: 'size/manage', resolve: {
                    sizes: SizeDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-size/manage-size.module').then(m => m.ManageSizeModule)
            },
            {
                path: 'color/manage', resolve: {
                    colors: ColorDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-color/manage-color.module').then(m => m.ManageColorModule)
            },
            {
                path: 'configuration/autofill', loadChildren: () => import('app/modules/admin/manage-autofill/manage-autofill.module').then(m => m.ManageAutofillModule)
            },
            {
                path: 'configuration/manage', resolve: {
                    categories: CategoryDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-configuration/manage-configuration.module').then(m => m.ManageConfigurationModule)
            },
            {
                path: 'keyword/manage', resolve: {
                    categories: KeywordDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-keyword/manage-keyword.module').then(m => m.ManageKeywordModule)
            },
            {
                path: 'franchise-categories/manage', resolve: {
                    categories: FranchiseCategoryDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-franchise-category/manage-franchise-category.module').then(m => m.ManageFranchiseCategoryModule)
            },
            {
                path: 'franchise-categories/manage/:id', resolve: {
                    franchises: FranchiseDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-franchise/manage-franchise.module').then(m => m.ManageFranchiseModule)
            },
            {
                path: 'order/all', resolve: {
                    orders: OrderDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-orders/manage-order.module').then(m => m.ManageOrderModule)
            },
            {
                path: 'orders', resolve: {
                    orders: OrderDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-orders/manage-order.module').then(m => m.ManageOrderModule)
            },
            {
                path: 'order/new', resolve: {
                    orders: OrderDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-orders/manage-order.module').then(m => m.ManageOrderModule)
            },
            {
                path: 'order/processed', resolve: {
                    orders: OrderDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-orders/manage-order.module').then(m => m.ManageOrderModule)
            },
            {
                path: 'order/shipped', resolve: {
                    orders: OrderDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-orders/manage-order.module').then(m => m.ManageOrderModule)
            },
            {
                path: 'order/delivered', resolve: {
                    orders: OrderDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-orders/manage-order.module').then(m => m.ManageOrderModule)
            },
            {
                path: 'order/cancelled', resolve: {
                    orders: OrderDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-orders/manage-order.module').then(m => m.ManageOrderModule)
            },
            {
                path: 'order/feedbacks', resolve: {
                    feedbacks: FeedbackDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-feedback/manage-feedback.module').then(m => m.ManageFeedbackModule)
            },
            {
                path: 'order/:id', resolve: {
                    subCategories: OrderDetailDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-order-details/order-detail.module').then(m => m.ManageOrderDetailModule)
            },
            {
                path: 'banner/manage', resolve: {
                    banners: BannerDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-banner/manage-banner.module').then(m => m.ManageBannerModule)
            },
            {
                path: 'faq/category/manage', resolve: {
                    categories: FaqCategoryDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-faq-category/manage-faq-category.module').then(m => m.ManageFaqCategoryModule)
            },
            {
                path: 'faq/manage', resolve: {
                    categories: FaqDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-faq/manage-faq.module').then(m => m.ManageFaqModule)
            },
            {
                path: 'faq/video', resolve: {
                    videos: FaqVideoDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-faq-video/manage-faq-video.module').then(m => m.ManageFaqVideoModule)
            },
            {
                path: 'product/manage', resolve: {
                    products: ProductDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-product/manage-product.module').then(m => m.ManageProductModule)
            },
            {
                path: 'product/manage/:id', resolve: {
                    products: ProductDetailDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-product/product-details/product-details.module').then(m => m.ProductDetailModule)
            },
            {
                path: 'product/manage/:prodId/version/:id', resolve: {
                    products: ProductVersionDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-product/product-versions/product-version.module').then(m => m.ProductVersionModule)
            },
            {
                path: 'product/manage/:prodId/option/:id/quantity', resolve: {
                    products: PricingQuantityDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-pricing-quantity/manage-pricing-quantity.module').then(m => m.ManagePricingQuantityModule)
            },
            {
                path: 'accessory/manage', resolve: {
                    products: ProductDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-product/manage-product.module').then(m => m.ManageProductModule)
            },
            {
                path: 'accessory/manage/:id', resolve: {
                    products: ProductDetailDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-product/product-details/product-details.module').then(m => m.ProductDetailModule)
            },
            {
                path: 'accessory/manage/:prodId/version/:id', resolve: {
                    products: ProductVersionDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-product/product-versions/product-version.module').then(m => m.ProductVersionModule)
            },
            {
                path: 'subscription/features', resolve: {
                    features: FeatureDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-subscription/manage-feature/manage-feature.module').then(m => m.ManageFeatureModule)
            },
            {
                path: 'subscription/category', resolve: {
                    categories: SubscriptionCategoryDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-subscription/manage-category/manage-subscription.category.module').then(m => m.ManageSubscriptionCategoryFeatureModule)
            },
            {
                path: 'subscription/category/:id', resolve: {
                    services: ServicesDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-subscription/manage-service/manage-service.module').then(m => m.ManageServiceModule)
            },
            {
                path: 'subscription/interests', resolve: {
                    interests: InterestDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-subscription/manage-interest/manage-interest.module').then(m => m.ManageInterestModule)
            },
            {
                path: 'subscription/plans', resolve: {
                    plans: PlansDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-subscription/manage-plan/manage-plan.module').then(m => m.ManagePlansModule)
            },
            {
                path: 'subscription/digital', resolve: {
                    plans: DigitalSubscriptionResolver
                }, loadChildren: () => import('app/modules/admin/manage-digital-subscriptions/manage-digital-subscriptions.module').then(m => m.ManageDigitalSubscriptionModule)
            },
            {
                path: 'subscription/digital/:id', resolve: {
                    plans: DigitalSubscriptionDetailDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-digital-subscription-details/manage-digital-subscription-detail.module').then(m => m.ManageDigitalSubscriptionDetailModule)
            },
            {
                path: 'tax/manage', resolve: {
                    taxes: TaxDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-tax/manage-tax.module').then(m => m.ManageTaxModule)
            },
            {
                path: 'email-signature/manage', resolve: {
                    signatures: EmailSignatureDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-email-signature/manage-email-signature.module').then(m => m.ManageEmailSignatureModule)
            }
        ]
    }
];
